<template>
  <Container
    :preloader="false"
    class="container"
  >
    <template #content>
      <form @submit.prevent="submit()">
        <h2>
          {{ $t('views.auth.sign-up-phone-edit.title') }}
        </h2>

        <InputPhone
          v-model="form.phone"
          :placeholder="`${$t('views.auth.sign-up.phone')}*`"
          :errors="errors.phone"
          :is-active="form.phone?.length"
          no-update
          auto-focus
        />

        <BlueButton
          :text="$t('views.auth.sign-up-phone-edit.submit')"
          :loading="loading"
          type="submit"
          :disabled="loading"
        />

        <BlueText
          tag="RouterLink"
          :text="$t('views.auth.sign-up-phone-edit.back')"
          :to="{ name: 'SignUpVerifyPhone' }"
        />
      </form>
    </template>
  </Container>
</template>

<script setup>
import { ref, computed, getCurrentInstance, watch } from 'vue'
import { phone } from 'phone'
import { useRoute, useRouter } from 'vue-router'
import { useVuelidate } from '@vuelidate/core'
import { required } from '@vuelidate/validators'

import Container from '@/ui/preloader/ItemPreloaderContainer.vue'
import InputPhone from '@/ui/inputs/Phone.vue'
import BlueButton from '@/ui/buttons/BlueButton.vue'
import BlueText from '@/ui/buttons/BlueText.vue'

const $router = useRouter()
const $route = useRoute()
const app = getCurrentInstance()
const { appContext: { config: { globalProperties: { $axios } } } } = app

const loading = ref(false)
const form = ref({
  phone: ''
})

const errors = ref({
  phone: []
})

const rules = computed(() => ({
  phone: { required }
}))

const v = useVuelidate(rules, form)

async function submit () {
  try {
    loading.value = true
    setError()

    if (_countError() === 0) {
      const number = form.value.phone.replace(/[^+\d]/g, '').replace('+', '')

      const { data } = await $axios.put(`auth/update-phone-by-hash/${$route.params.hash}`,
        { phone: number })

      if (data.status) {
        $router.push({ name: 'SignUpVerifyPhone', params: { hash: data.hash } })
      }
    }
  } catch (err) {
    const { response: { data: { messages } } } = err

    if (messages === 'Phone already exists') {
      errors.value.phone.push('errors.phone-used')
    } else {
      $router.push({ name: 'SignIn' })
    }

    throw new Error(err)
  } finally {
    loading.value = false
  }
}

function setError () {
  const errorsForm = v.value
  const state = form.value

  errors.value = {
    phone: []
  }

  const validate = phone(state.phone)

  if (errorsForm.phone.required.$invalid) {
    errors.value.phone.push('errors.required')
  }
  if (!validate.isValid) {
    errors.value.phone.push('errors.phone')
  }
}

function _countError () {
  const state = errors.value
  var count = 0

  count += state.phone.length

  return count
}

watch(() => $route.params.locale, () => {
  setError()
})
</script>

<style lang="scss" scoped>
.container {
  padding: 30px 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;

  form {
    display: flex;
    flex-direction: column;
    gap: 30px;

    h2 {
      font-size: 32px;
      line-height: 100%;
      color: #202539;
      font-family: SemiBold;
    }
  }
}
</style>
